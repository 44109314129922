import { default as api_45legacyXycKSOOVijMeta } from "/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardskp2kGeqOQ5Meta } from "/codebuild/output/src380723514/src/horizon/pages/awards.vue?macro=true";
import { default as programshmYWtupD6aMeta } from "/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93JWHZw3Y6cLMeta } from "/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93mIxDANPnzcMeta } from "/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexRnhL00B7wbMeta } from "/codebuild/output/src380723514/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta } from "/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93vOYAM5DJKDMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93yp8AIxYTqAMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutmccpi9JuNPMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/about.vue?macro=true";
import { default as indexax4TCx0i4ZMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productu8Nko29ndSMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexuDMBvjTXR3Meta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesx3yeulR7q2Meta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivei4PoFOtIhLMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexs3U8fHgqqBMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexBZATXCj2V7Meta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93dqHj2g26kjMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexEDCoAzFFUhMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversityNajE2nnSGzMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexxGsTCJp2PZMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiency9mDY7REXfyMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safety6MlBRqbzBPMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilitymXSGqzCKnJMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsXgmM5TqB8ZMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexpFqpX1DyDMMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releaseskfU0OZK8DxMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacy74HrqAmavCMeta } from "/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolISmQiWvWOWMeta } from "/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93S2AAcycsqwMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93q9MlijbDCrMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexHRpda5leX3Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93RmspbWhvcgMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93VH4wwzUpV2Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexOnVKTkRjF2Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93bxbpdQ4pzaMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondtE545WjnGaMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceeY4RwlS2W8Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceXvJf3GcJTRMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as index4GBAglJx1jMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetv70mbJ2ajGMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceyWrVuvrZKpMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performanceu2M8wrGs5eMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovator29pKiJhrBwMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexijah8xd9vLMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetdNuhD6yX75Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetEVWB2zwgB6Meta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexI0DKrGzl7FMeta } from "/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93iEbqLBxx5YMeta } from "/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexqLdTWr8RHiMeta } from "/codebuild/output/src380723514/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appLBiYXGnTwEMeta } from "/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93fI5HPz4JPRMeta } from "/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93tFH1wmCOoiMeta } from "/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93oG4EIGkidmMeta } from "/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ySRsUaIOa1Meta } from "/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93zdnVfTkZCWMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentgvXIKlhVyGMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexMbastBy7FqMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsgFwi2XFQVLMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsCraGszJxcJMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexEZK0EFfhjJMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programolGdd78DReMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roi8yKNvjNHPdMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexmpYFiqCCmBMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futuretRi1CXN6C7Meta } from "/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsRmkMskb6A6Meta } from "/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexBdehBHZ7YzMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataIUBNDM5kAIMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93jW40k1gxEiMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indext781wFE9sDMeta } from "/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexxziqXm4cy9Meta } from "/codebuild/output/src380723514/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93GTOlfWza90Meta } from "/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93oauA14CUiBMeta } from "/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexPiXFageRV8Meta } from "/codebuild/output/src380723514/src/horizon/pages/industries/index.vue?macro=true";
import { default as cities2JcsQRxf81Meta } from "/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexWalzftsh6FMeta } from "/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexnwnxu9lbarMeta } from "/codebuild/output/src380723514/src/horizon/pages/invite/index.vue?macro=true";
import { default as candidate_45privacy_45noticeYbWS8S7uHMMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentN9QfsxdvShMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyGAWAwzmbcIMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumgnDOBWX6PtMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsAMhSTQoCLDMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantykzgFhBLJelMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slaYmYTFKPSn1Meta } from "/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductSD7RSt7B5jMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceoOCWbHNcwRMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyhiyZl0YCJPMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdata50ZukeWhwCMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as security2HWjLsurwiMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportFJcuvFJTHpMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45servicevFKnzfDi9LMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesh8cUPr7vfGMeta } from "/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingzNT7gaKwcMMeta } from "/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_936fIV8hMLnVMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93YUTiSN6viRMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingrHLstnGW3PMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsSFsBZcSanLMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as construction0jKcMjk52TMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue?macro=true";
import { default as eversource3P95RCijgoMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue?macro=true";
import { default as fleet_45management0nKQWvUvYpMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as groundworksU9xbHwaiOvMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue?macro=true";
import { default as nationwidec4dId7HQ5cMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingAOOtEFrHTmMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstAX4RkU6kANMeta } from "/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingCnJrzlVXk4Meta } from "/codebuild/output/src380723514/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93CqYwDG1ZgfMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93R1hx2QXBsIMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingvyijYDMDgDMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingb90LJxYcr4Meta } from "/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringXrBwi8pMzoMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailerseNWU93HQ0sMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackinghWZRN2wXqAMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexDfDghf7dIQMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_930aaM0tS5zaMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as index2HfJ5dYvxkMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingfR6zu11Z2SMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexOePakGekwDMeta } from "/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingHcrQjIsg6dMeta } from "/codebuild/output/src380723514/src/horizon/pages/racing.vue?macro=true";
import { default as referralsZLdJtDgR27Meta } from "/codebuild/output/src380723514/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_932zM96pw9PuMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assets5InFs8R5H0Meta } from "/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93AMi4S0auGyMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45center7LhRaVaTPMMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribeYwqQY7zpgfMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_93GM4QdyYHZpMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_930WERZpUlVIMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as index2mjwtypTIgMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as index2sdZTub637Meta } from "/codebuild/output/src380723514/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_932uEKNKbvsDMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93swn2n8IfQqMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexrNcJybvDtIMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsara436N4EwSEKMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsX2QAu6br6OMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as index2iJ5xxcKHLMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesnV5ajUt9dVMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaraO9i85dt7A4Meta } from "/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tour3tsI32NwMyMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosljwwPGOzwmMeta } from "/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexEgxzbkphQqMeta } from "/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesRE7v9pbYvQMeta } from "/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitxJ1X8OtLWMMeta } from "/codebuild/output/src380723514/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93y0btjjYsc1Meta } from "/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93aRgkVkCgx3Meta } from "/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexwIGlrDEgeRMeta } from "/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue?macro=true";
import { default as security0SaSpRKxqaMeta } from "/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue?macro=true";
import { default as space4MnNqA6AtsMeta } from "/codebuild/output/src380723514/src/horizon/pages/space.vue?macro=true";
import { default as sparkVudL7sdBlrMeta } from "/codebuild/output/src380723514/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93kaxQe5BNKJMeta } from "/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93LPfRXeU8fPMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93BVqnUxYDSCMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policy72vLrX6lFjMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqmZ62OMKMcYMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishk6sE741JZqMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingEIcPeocO4gMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexMC4i2bb8MEMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialRKCRVfAbcwMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementp17E475rmtMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policy8aifuR1U8RMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexjDqCVMzRc6Meta } from "/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featureserdquVgFQ3Meta } from "/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listMzVuY5Us5bMeta } from "/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesyzpavNFkL0Meta } from "/codebuild/output/src380723514/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45page7rOZNfahrPMeta } from "/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingoYak3nS9i7Meta } from "/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralc1iQ5pY7NdMeta } from "/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouALcvY9QmeIMeta } from "/codebuild/output/src380723514/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteDpKWiBVGlOMeta } from "/codebuild/output/src380723514/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as pluskY21Zze1qBMeta } from "/codebuild/output/src380723514/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierclitnEkAf7Meta } from "/codebuild/output/src380723514/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterCgaCFcpTpOMeta } from "/codebuild/output/src380723514/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processzAF76eC8q7Meta } from "/codebuild/output/src380723514/src/horizon/pages/trial/process.vue?macro=true";
import { default as support7zGgYXsJnyMeta } from "/codebuild/output/src380723514/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93UaPEkUBKd6Meta } from "/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexGBmgmhVFEuMeta } from "/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93j8bgaki8pGMeta } from "/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___ca",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___nl",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___uk",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___de",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___mx",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___fr",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyXycKSOOVijMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyXycKSOOVijMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyXycKSOOVijMeta || {},
    alias: api_45legacyXycKSOOVijMeta?.alias || [],
    redirect: api_45legacyXycKSOOVijMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___en-US",
    path: awardskp2kGeqOQ5Meta?.path ?? "/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___ca",
    path: awardskp2kGeqOQ5Meta?.path ?? "/ca/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___nl",
    path: awardskp2kGeqOQ5Meta?.path ?? "/nl/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___uk",
    path: awardskp2kGeqOQ5Meta?.path ?? "/uk/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___de",
    path: awardskp2kGeqOQ5Meta?.path ?? "/de/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___mx",
    path: awardskp2kGeqOQ5Meta?.path ?? "/mx/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___fr",
    path: awardskp2kGeqOQ5Meta?.path ?? "/fr/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardskp2kGeqOQ5Meta?.name ?? "awards___fr-ca",
    path: awardskp2kGeqOQ5Meta?.path ?? "/fr-ca/awards",
    meta: awardskp2kGeqOQ5Meta || {},
    alias: awardskp2kGeqOQ5Meta?.alias || [],
    redirect: awardskp2kGeqOQ5Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___en-US",
    path: programshmYWtupD6aMeta?.path ?? "/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___ca",
    path: programshmYWtupD6aMeta?.path ?? "/ca/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___nl",
    path: programshmYWtupD6aMeta?.path ?? "/nl/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___uk",
    path: programshmYWtupD6aMeta?.path ?? "/uk/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___de",
    path: programshmYWtupD6aMeta?.path ?? "/de/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___mx",
    path: programshmYWtupD6aMeta?.path ?? "/mx/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___fr",
    path: programshmYWtupD6aMeta?.path ?? "/fr/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programshmYWtupD6aMeta?.name ?? "benefits-programs___fr-ca",
    path: programshmYWtupD6aMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programshmYWtupD6aMeta || {},
    alias: programshmYWtupD6aMeta?.alias || [],
    redirect: programshmYWtupD6aMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___ca",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___mx",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___uk",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___fr",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JWHZw3Y6cLMeta?.name ?? "blog-slug___de",
    path: _91slug_93JWHZw3Y6cLMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93JWHZw3Y6cLMeta || {},
    alias: _91slug_93JWHZw3Y6cLMeta?.alias || [],
    redirect: _91slug_93JWHZw3Y6cLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mIxDANPnzcMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93mIxDANPnzcMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93mIxDANPnzcMeta || {},
    alias: _91slug_93mIxDANPnzcMeta?.alias || [],
    redirect: _91slug_93mIxDANPnzcMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___en-US",
    path: indexRnhL00B7wbMeta?.path ?? "/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___ca",
    path: indexRnhL00B7wbMeta?.path ?? "/ca/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___mx",
    path: indexRnhL00B7wbMeta?.path ?? "/mx/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___uk",
    path: indexRnhL00B7wbMeta?.path ?? "/uk/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___fr",
    path: indexRnhL00B7wbMeta?.path ?? "/fr/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRnhL00B7wbMeta?.name ?? "blog___de",
    path: indexRnhL00B7wbMeta?.path ?? "/de/blog",
    meta: indexRnhL00B7wbMeta || {},
    alias: indexRnhL00B7wbMeta?.alias || [],
    redirect: indexRnhL00B7wbMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondcXoOB2a3svMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vOYAM5DJKDMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93vOYAM5DJKDMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93vOYAM5DJKDMeta || {},
    alias: _91slug_93vOYAM5DJKDMeta?.alias || [],
    redirect: _91slug_93vOYAM5DJKDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___en-US",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___ca",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___nl",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___uk",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___de",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___mx",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___fr",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yp8AIxYTqAMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93yp8AIxYTqAMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93yp8AIxYTqAMeta || {},
    alias: _91slug_93yp8AIxYTqAMeta?.alias || [],
    redirect: _91slug_93yp8AIxYTqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___en-US",
    path: aboutmccpi9JuNPMeta?.path ?? "/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___ca",
    path: aboutmccpi9JuNPMeta?.path ?? "/ca/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___nl",
    path: aboutmccpi9JuNPMeta?.path ?? "/nl/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___uk",
    path: aboutmccpi9JuNPMeta?.path ?? "/uk/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___de",
    path: aboutmccpi9JuNPMeta?.path ?? "/de/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___mx",
    path: aboutmccpi9JuNPMeta?.path ?? "/mx/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___fr",
    path: aboutmccpi9JuNPMeta?.path ?? "/fr/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutmccpi9JuNPMeta?.name ?? "company-about___fr-ca",
    path: aboutmccpi9JuNPMeta?.path ?? "/fr-ca/company/about",
    meta: aboutmccpi9JuNPMeta || {},
    alias: aboutmccpi9JuNPMeta?.alias || [],
    redirect: aboutmccpi9JuNPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___en-US",
    path: indexax4TCx0i4ZMeta?.path ?? "/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___ca",
    path: indexax4TCx0i4ZMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___nl",
    path: indexax4TCx0i4ZMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___uk",
    path: indexax4TCx0i4ZMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___de",
    path: indexax4TCx0i4ZMeta?.path ?? "/de/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___mx",
    path: indexax4TCx0i4ZMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___fr",
    path: indexax4TCx0i4ZMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexax4TCx0i4ZMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexax4TCx0i4ZMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexax4TCx0i4ZMeta || {},
    alias: indexax4TCx0i4ZMeta?.alias || [],
    redirect: indexax4TCx0i4ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productu8Nko29ndSMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productu8Nko29ndSMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productu8Nko29ndSMeta || {},
    alias: engineering_45and_45productu8Nko29ndSMeta?.alias || [],
    redirect: engineering_45and_45productu8Nko29ndSMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___en-US",
    path: indexuDMBvjTXR3Meta?.path ?? "/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___ca",
    path: indexuDMBvjTXR3Meta?.path ?? "/ca/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___nl",
    path: indexuDMBvjTXR3Meta?.path ?? "/nl/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___uk",
    path: indexuDMBvjTXR3Meta?.path ?? "/uk/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___de",
    path: indexuDMBvjTXR3Meta?.path ?? "/de/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___mx",
    path: indexuDMBvjTXR3Meta?.path ?? "/mx/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___fr",
    path: indexuDMBvjTXR3Meta?.path ?? "/fr/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexuDMBvjTXR3Meta?.name ?? "company-careers-departments___fr-ca",
    path: indexuDMBvjTXR3Meta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexuDMBvjTXR3Meta || {},
    alias: indexuDMBvjTXR3Meta?.alias || [],
    redirect: indexuDMBvjTXR3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___en-US",
    path: salesx3yeulR7q2Meta?.path ?? "/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___ca",
    path: salesx3yeulR7q2Meta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___nl",
    path: salesx3yeulR7q2Meta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___uk",
    path: salesx3yeulR7q2Meta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___de",
    path: salesx3yeulR7q2Meta?.path ?? "/de/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___mx",
    path: salesx3yeulR7q2Meta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___fr",
    path: salesx3yeulR7q2Meta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesx3yeulR7q2Meta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesx3yeulR7q2Meta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesx3yeulR7q2Meta || {},
    alias: salesx3yeulR7q2Meta?.alias || [],
    redirect: salesx3yeulR7q2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivei4PoFOtIhLMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archivei4PoFOtIhLMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivei4PoFOtIhLMeta || {},
    alias: emerging_45talent_45archivei4PoFOtIhLMeta?.alias || [],
    redirect: emerging_45talent_45archivei4PoFOtIhLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___en-US",
    path: indexs3U8fHgqqBMeta?.path ?? "/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___ca",
    path: indexs3U8fHgqqBMeta?.path ?? "/ca/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___nl",
    path: indexs3U8fHgqqBMeta?.path ?? "/nl/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___uk",
    path: indexs3U8fHgqqBMeta?.path ?? "/uk/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___de",
    path: indexs3U8fHgqqBMeta?.path ?? "/de/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___mx",
    path: indexs3U8fHgqqBMeta?.path ?? "/mx/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___fr",
    path: indexs3U8fHgqqBMeta?.path ?? "/fr/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexs3U8fHgqqBMeta?.name ?? "company-careers___fr-ca",
    path: indexs3U8fHgqqBMeta?.path ?? "/fr-ca/company/careers",
    meta: indexs3U8fHgqqBMeta || {},
    alias: indexs3U8fHgqqBMeta?.alias || [],
    redirect: indexs3U8fHgqqBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___en-US",
    path: indexBZATXCj2V7Meta?.path ?? "/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___ca",
    path: indexBZATXCj2V7Meta?.path ?? "/ca/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___nl",
    path: indexBZATXCj2V7Meta?.path ?? "/nl/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___uk",
    path: indexBZATXCj2V7Meta?.path ?? "/uk/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___de",
    path: indexBZATXCj2V7Meta?.path ?? "/de/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___mx",
    path: indexBZATXCj2V7Meta?.path ?? "/mx/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___fr",
    path: indexBZATXCj2V7Meta?.path ?? "/fr/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZATXCj2V7Meta?.name ?? "company-careers-locations___fr-ca",
    path: indexBZATXCj2V7Meta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexBZATXCj2V7Meta || {},
    alias: indexBZATXCj2V7Meta?.alias || [],
    redirect: indexBZATXCj2V7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqHj2g26kjMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93dqHj2g26kjMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93dqHj2g26kjMeta || {},
    alias: _91slug_93dqHj2g26kjMeta?.alias || [],
    redirect: _91slug_93dqHj2g26kjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___en-US",
    path: indexEDCoAzFFUhMeta?.path ?? "/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___ca",
    path: indexEDCoAzFFUhMeta?.path ?? "/ca/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___nl",
    path: indexEDCoAzFFUhMeta?.path ?? "/nl/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___uk",
    path: indexEDCoAzFFUhMeta?.path ?? "/uk/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___de",
    path: indexEDCoAzFFUhMeta?.path ?? "/de/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___mx",
    path: indexEDCoAzFFUhMeta?.path ?? "/mx/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___fr",
    path: indexEDCoAzFFUhMeta?.path ?? "/fr/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexEDCoAzFFUhMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexEDCoAzFFUhMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexEDCoAzFFUhMeta || {},
    alias: indexEDCoAzFFUhMeta?.alias || [],
    redirect: indexEDCoAzFFUhMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___en-US",
    path: diversityNajE2nnSGzMeta?.path ?? "/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___ca",
    path: diversityNajE2nnSGzMeta?.path ?? "/ca/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___nl",
    path: diversityNajE2nnSGzMeta?.path ?? "/nl/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___uk",
    path: diversityNajE2nnSGzMeta?.path ?? "/uk/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___de",
    path: diversityNajE2nnSGzMeta?.path ?? "/de/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___mx",
    path: diversityNajE2nnSGzMeta?.path ?? "/mx/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___fr",
    path: diversityNajE2nnSGzMeta?.path ?? "/fr/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityNajE2nnSGzMeta?.name ?? "company-diversity___fr-ca",
    path: diversityNajE2nnSGzMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversityNajE2nnSGzMeta || {},
    alias: diversityNajE2nnSGzMeta?.alias || [],
    redirect: diversityNajE2nnSGzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___en-US",
    path: indexxGsTCJp2PZMeta?.path ?? "/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___ca",
    path: indexxGsTCJp2PZMeta?.path ?? "/ca/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___nl",
    path: indexxGsTCJp2PZMeta?.path ?? "/nl/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___uk",
    path: indexxGsTCJp2PZMeta?.path ?? "/uk/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___de",
    path: indexxGsTCJp2PZMeta?.path ?? "/de/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___mx",
    path: indexxGsTCJp2PZMeta?.path ?? "/mx/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___fr",
    path: indexxGsTCJp2PZMeta?.path ?? "/fr/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGsTCJp2PZMeta?.name ?? "company___fr-ca",
    path: indexxGsTCJp2PZMeta?.path ?? "/fr-ca/company",
    meta: indexxGsTCJp2PZMeta || {},
    alias: indexxGsTCJp2PZMeta?.alias || [],
    redirect: indexxGsTCJp2PZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiency9mDY7REXfyMeta?.path ?? "/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiency9mDY7REXfyMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiency9mDY7REXfyMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiency9mDY7REXfyMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___de",
    path: efficiency9mDY7REXfyMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiency9mDY7REXfyMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiency9mDY7REXfyMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9mDY7REXfyMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiency9mDY7REXfyMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiency9mDY7REXfyMeta || {},
    alias: efficiency9mDY7REXfyMeta?.alias || [],
    redirect: efficiency9mDY7REXfyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___en-US",
    path: safety6MlBRqbzBPMeta?.path ?? "/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___ca",
    path: safety6MlBRqbzBPMeta?.path ?? "/ca/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___nl",
    path: safety6MlBRqbzBPMeta?.path ?? "/nl/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___uk",
    path: safety6MlBRqbzBPMeta?.path ?? "/uk/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___de",
    path: safety6MlBRqbzBPMeta?.path ?? "/de/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___mx",
    path: safety6MlBRqbzBPMeta?.path ?? "/mx/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___fr",
    path: safety6MlBRqbzBPMeta?.path ?? "/fr/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety6MlBRqbzBPMeta?.name ?? "company-mission-safety___fr-ca",
    path: safety6MlBRqbzBPMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safety6MlBRqbzBPMeta || {},
    alias: safety6MlBRqbzBPMeta?.alias || [],
    redirect: safety6MlBRqbzBPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitymXSGqzCKnJMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilitymXSGqzCKnJMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilitymXSGqzCKnJMeta || {},
    alias: sustainabilitymXSGqzCKnJMeta?.alias || [],
    redirect: sustainabilitymXSGqzCKnJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsXgmM5TqB8ZMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsXgmM5TqB8ZMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsXgmM5TqB8ZMeta || {},
    alias: in_45the_45newsXgmM5TqB8ZMeta?.alias || [],
    redirect: in_45the_45newsXgmM5TqB8ZMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexpFqpX1DyDMMeta?.name ?? "company-news___en-US",
    path: indexpFqpX1DyDMMeta?.path ?? "/company/news",
    meta: indexpFqpX1DyDMMeta || {},
    alias: indexpFqpX1DyDMMeta?.alias || [],
    redirect: indexpFqpX1DyDMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexpFqpX1DyDMMeta?.name ?? "company-news___ca",
    path: indexpFqpX1DyDMMeta?.path ?? "/ca/company/news",
    meta: indexpFqpX1DyDMMeta || {},
    alias: indexpFqpX1DyDMMeta?.alias || [],
    redirect: indexpFqpX1DyDMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexpFqpX1DyDMMeta?.name ?? "company-news___mx",
    path: indexpFqpX1DyDMMeta?.path ?? "/mx/company/news",
    meta: indexpFqpX1DyDMMeta || {},
    alias: indexpFqpX1DyDMMeta?.alias || [],
    redirect: indexpFqpX1DyDMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexpFqpX1DyDMMeta?.name ?? "company-news___uk",
    path: indexpFqpX1DyDMMeta?.path ?? "/uk/company/news",
    meta: indexpFqpX1DyDMMeta || {},
    alias: indexpFqpX1DyDMMeta?.alias || [],
    redirect: indexpFqpX1DyDMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___de",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releaseskfU0OZK8DxMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releaseskfU0OZK8DxMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releaseskfU0OZK8DxMeta || {},
    alias: press_45releaseskfU0OZK8DxMeta?.alias || [],
    redirect: press_45releaseskfU0OZK8DxMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___en-US",
    path: privacy74HrqAmavCMeta?.path ?? "/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___ca",
    path: privacy74HrqAmavCMeta?.path ?? "/ca/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___nl",
    path: privacy74HrqAmavCMeta?.path ?? "/nl/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___uk",
    path: privacy74HrqAmavCMeta?.path ?? "/uk/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___de",
    path: privacy74HrqAmavCMeta?.path ?? "/de/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___mx",
    path: privacy74HrqAmavCMeta?.path ?? "/mx/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___fr",
    path: privacy74HrqAmavCMeta?.path ?? "/fr/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy74HrqAmavCMeta?.name ?? "company-privacy___fr-ca",
    path: privacy74HrqAmavCMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacy74HrqAmavCMeta || {},
    alias: privacy74HrqAmavCMeta?.alias || [],
    redirect: privacy74HrqAmavCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolISmQiWvWOWMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolISmQiWvWOWMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolISmQiWvWOWMeta || {},
    alias: cost_45assessment_45toolISmQiWvWOWMeta?.alias || [],
    redirect: cost_45assessment_45toolISmQiWvWOWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___ca",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___nl",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___uk",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___de",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___mx",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___fr",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S2AAcycsqwMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93S2AAcycsqwMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93S2AAcycsqwMeta || {},
    alias: _91slug_93S2AAcycsqwMeta?.alias || [],
    redirect: _91slug_93S2AAcycsqwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q9MlijbDCrMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93q9MlijbDCrMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93q9MlijbDCrMeta || {},
    alias: _91slug_93q9MlijbDCrMeta?.alias || [],
    redirect: _91slug_93q9MlijbDCrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___en-US",
    path: indexHRpda5leX3Meta?.path ?? "/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___ca",
    path: indexHRpda5leX3Meta?.path ?? "/ca/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___nl",
    path: indexHRpda5leX3Meta?.path ?? "/nl/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___uk",
    path: indexHRpda5leX3Meta?.path ?? "/uk/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___de",
    path: indexHRpda5leX3Meta?.path ?? "/de/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___mx",
    path: indexHRpda5leX3Meta?.path ?? "/mx/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___fr",
    path: indexHRpda5leX3Meta?.path ?? "/fr/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRpda5leX3Meta?.name ?? "customers___fr-ca",
    path: indexHRpda5leX3Meta?.path ?? "/fr-ca/customers",
    meta: indexHRpda5leX3Meta || {},
    alias: indexHRpda5leX3Meta?.alias || [],
    redirect: indexHRpda5leX3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RmspbWhvcgMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93RmspbWhvcgMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93RmspbWhvcgMeta || {},
    alias: _91slug_93RmspbWhvcgMeta?.alias || [],
    redirect: _91slug_93RmspbWhvcgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VH4wwzUpV2Meta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93VH4wwzUpV2Meta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93VH4wwzUpV2Meta || {},
    alias: _91slug_93VH4wwzUpV2Meta?.alias || [],
    redirect: _91slug_93VH4wwzUpV2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___en-US",
    path: indexOnVKTkRjF2Meta?.path ?? "/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___ca",
    path: indexOnVKTkRjF2Meta?.path ?? "/ca/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___nl",
    path: indexOnVKTkRjF2Meta?.path ?? "/nl/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___uk",
    path: indexOnVKTkRjF2Meta?.path ?? "/uk/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___de",
    path: indexOnVKTkRjF2Meta?.path ?? "/de/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___mx",
    path: indexOnVKTkRjF2Meta?.path ?? "/mx/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___fr",
    path: indexOnVKTkRjF2Meta?.path ?? "/fr/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnVKTkRjF2Meta?.name ?? "customers-tips___fr-ca",
    path: indexOnVKTkRjF2Meta?.path ?? "/fr-ca/customers/tips",
    meta: indexOnVKTkRjF2Meta || {},
    alias: indexOnVKTkRjF2Meta?.alias || [],
    redirect: indexOnVKTkRjF2Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bxbpdQ4pzaMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93bxbpdQ4pzaMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93bxbpdQ4pzaMeta || {},
    alias: _91_46_46_46slug_93bxbpdQ4pzaMeta?.alias || [],
    redirect: _91_46_46_46slug_93bxbpdQ4pzaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtE545WjnGaMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondtE545WjnGaMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtE545WjnGaMeta || {},
    alias: above_45beyondtE545WjnGaMeta?.alias || [],
    redirect: above_45beyondtE545WjnGaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeY4RwlS2W8Meta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceeY4RwlS2W8Meta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeY4RwlS2W8Meta || {},
    alias: excellence_45performanceeY4RwlS2W8Meta?.alias || [],
    redirect: excellence_45performanceeY4RwlS2W8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceXvJf3GcJTRMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceXvJf3GcJTRMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceXvJf3GcJTRMeta || {},
    alias: excellence_45serviceXvJf3GcJTRMeta?.alias || [],
    redirect: excellence_45serviceXvJf3GcJTRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___en-US",
    path: index4GBAglJx1jMeta?.path ?? "/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___ca",
    path: index4GBAglJx1jMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___nl",
    path: index4GBAglJx1jMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___uk",
    path: index4GBAglJx1jMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___de",
    path: index4GBAglJx1jMeta?.path ?? "/de/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___mx",
    path: index4GBAglJx1jMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___fr",
    path: index4GBAglJx1jMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: index4GBAglJx1jMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: index4GBAglJx1jMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: index4GBAglJx1jMeta || {},
    alias: index4GBAglJx1jMeta?.alias || [],
    redirect: index4GBAglJx1jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetv70mbJ2ajGMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetv70mbJ2ajGMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetv70mbJ2ajGMeta || {},
    alias: safest_45fleetv70mbJ2ajGMeta?.alias || [],
    redirect: safest_45fleetv70mbJ2ajGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceyWrVuvrZKpMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceyWrVuvrZKpMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceyWrVuvrZKpMeta || {},
    alias: driver_45performanceyWrVuvrZKpMeta?.alias || [],
    redirect: driver_45performanceyWrVuvrZKpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceu2M8wrGs5eMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performanceu2M8wrGs5eMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceu2M8wrGs5eMeta || {},
    alias: excellence_45performanceu2M8wrGs5eMeta?.alias || [],
    redirect: excellence_45performanceu2M8wrGs5eMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator29pKiJhrBwMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovator29pKiJhrBwMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator29pKiJhrBwMeta || {},
    alias: fleet_45innovator29pKiJhrBwMeta?.alias || [],
    redirect: fleet_45innovator29pKiJhrBwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexijah8xd9vLMeta?.path ?? "/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexijah8xd9vLMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexijah8xd9vLMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexijah8xd9vLMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___de",
    path: indexijah8xd9vLMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexijah8xd9vLMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexijah8xd9vLMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexijah8xd9vLMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexijah8xd9vLMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexijah8xd9vLMeta || {},
    alias: indexijah8xd9vLMeta?.alias || [],
    redirect: indexijah8xd9vLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetdNuhD6yX75Meta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetdNuhD6yX75Meta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetdNuhD6yX75Meta || {},
    alias: public_45fleetdNuhD6yX75Meta?.alias || [],
    redirect: public_45fleetdNuhD6yX75Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetEVWB2zwgB6Meta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetEVWB2zwgB6Meta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetEVWB2zwgB6Meta || {},
    alias: safest_45fleetEVWB2zwgB6Meta?.alias || [],
    redirect: safest_45fleetEVWB2zwgB6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetEVWB2zwgB6Meta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetEVWB2zwgB6Meta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetEVWB2zwgB6Meta || {},
    alias: safest_45fleetEVWB2zwgB6Meta?.alias || [],
    redirect: safest_45fleetEVWB2zwgB6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___en-US",
    path: indexI0DKrGzl7FMeta?.path ?? "/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___ca",
    path: indexI0DKrGzl7FMeta?.path ?? "/ca/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___nl",
    path: indexI0DKrGzl7FMeta?.path ?? "/nl/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___uk",
    path: indexI0DKrGzl7FMeta?.path ?? "/uk/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___de",
    path: indexI0DKrGzl7FMeta?.path ?? "/de/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___mx",
    path: indexI0DKrGzl7FMeta?.path ?? "/mx/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___fr",
    path: indexI0DKrGzl7FMeta?.path ?? "/fr/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0DKrGzl7FMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexI0DKrGzl7FMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexI0DKrGzl7FMeta || {},
    alias: indexI0DKrGzl7FMeta?.alias || [],
    redirect: indexI0DKrGzl7FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___en-US",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___ca",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___nl",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___uk",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___de",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___mx",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___fr",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iEbqLBxx5YMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_93iEbqLBxx5YMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93iEbqLBxx5YMeta || {},
    alias: _91slug_93iEbqLBxx5YMeta?.alias || [],
    redirect: _91slug_93iEbqLBxx5YMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___en-US",
    path: indexqLdTWr8RHiMeta?.path ?? "/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___ca",
    path: indexqLdTWr8RHiMeta?.path ?? "/ca/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___nl",
    path: indexqLdTWr8RHiMeta?.path ?? "/nl/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___uk",
    path: indexqLdTWr8RHiMeta?.path ?? "/uk/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___de",
    path: indexqLdTWr8RHiMeta?.path ?? "/de/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___mx",
    path: indexqLdTWr8RHiMeta?.path ?? "/mx/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___fr",
    path: indexqLdTWr8RHiMeta?.path ?? "/fr/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLdTWr8RHiMeta?.name ?? "events___fr-ca",
    path: indexqLdTWr8RHiMeta?.path ?? "/fr-ca/events",
    meta: indexqLdTWr8RHiMeta || {},
    alias: indexqLdTWr8RHiMeta?.alias || [],
    redirect: indexqLdTWr8RHiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___ca",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___nl",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___uk",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___de",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___mx",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___fr",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appLBiYXGnTwEMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appLBiYXGnTwEMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appLBiYXGnTwEMeta || {},
    alias: fleet_45appLBiYXGnTwEMeta?.alias || [],
    redirect: fleet_45appLBiYXGnTwEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fI5HPz4JPRMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93fI5HPz4JPRMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93fI5HPz4JPRMeta || {},
    alias: _91slug_93fI5HPz4JPRMeta?.alias || [],
    redirect: _91slug_93fI5HPz4JPRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___de",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tFH1wmCOoiMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93tFH1wmCOoiMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93tFH1wmCOoiMeta || {},
    alias: _91slug_93tFH1wmCOoiMeta?.alias || [],
    redirect: _91slug_93tFH1wmCOoiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oG4EIGkidmMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93oG4EIGkidmMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93oG4EIGkidmMeta || {},
    alias: _91slug_93oG4EIGkidmMeta?.alias || [],
    redirect: _91slug_93oG4EIGkidmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___en-US",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___ca",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___nl",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___uk",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___de",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___mx",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___fr",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ySRsUaIOa1Meta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93ySRsUaIOa1Meta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93ySRsUaIOa1Meta || {},
    alias: _91slug_93ySRsUaIOa1Meta?.alias || [],
    redirect: _91slug_93ySRsUaIOa1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___ca",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___nl",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___uk",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___de",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___mx",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___fr",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zdnVfTkZCWMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93zdnVfTkZCWMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93zdnVfTkZCWMeta || {},
    alias: _91slug_93zdnVfTkZCWMeta?.alias || [],
    redirect: _91slug_93zdnVfTkZCWMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentgvXIKlhVyGMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentgvXIKlhVyGMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentgvXIKlhVyGMeta || {},
    alias: attract_45retain_45talentgvXIKlhVyGMeta?.alias || [],
    redirect: attract_45retain_45talentgvXIKlhVyGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta || {},
    alias: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsu2jLuJwX7KMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexMbastBy7FqMeta?.path ?? "/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexMbastBy7FqMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexMbastBy7FqMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexMbastBy7FqMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___de",
    path: indexMbastBy7FqMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexMbastBy7FqMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexMbastBy7FqMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbastBy7FqMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexMbastBy7FqMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexMbastBy7FqMeta || {},
    alias: indexMbastBy7FqMeta?.alias || [],
    redirect: indexMbastBy7FqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsgFwi2XFQVLMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsgFwi2XFQVLMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsgFwi2XFQVLMeta || {},
    alias: protect_45optimize_45assetsgFwi2XFQVLMeta?.alias || [],
    redirect: protect_45optimize_45assetsgFwi2XFQVLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsCraGszJxcJMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsCraGszJxcJMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsCraGszJxcJMeta || {},
    alias: best_45dash_45camsCraGszJxcJMeta?.alias || [],
    redirect: best_45dash_45camsCraGszJxcJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexEZK0EFfhjJMeta?.path ?? "/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___ca",
    path: indexEZK0EFfhjJMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___nl",
    path: indexEZK0EFfhjJMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___uk",
    path: indexEZK0EFfhjJMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___de",
    path: indexEZK0EFfhjJMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___mx",
    path: indexEZK0EFfhjJMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___fr",
    path: indexEZK0EFfhjJMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexEZK0EFfhjJMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexEZK0EFfhjJMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexEZK0EFfhjJMeta || {},
    alias: indexEZK0EFfhjJMeta?.alias || [],
    redirect: indexEZK0EFfhjJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programolGdd78DReMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programolGdd78DReMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programolGdd78DReMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programolGdd78DReMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programolGdd78DReMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programolGdd78DReMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programolGdd78DReMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programolGdd78DReMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programolGdd78DReMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programolGdd78DReMeta || {},
    alias: safety_45programolGdd78DReMeta?.alias || [],
    redirect: safety_45programolGdd78DReMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi8yKNvjNHPdMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roi8yKNvjNHPdMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roi8yKNvjNHPdMeta || {},
    alias: safety_45roi8yKNvjNHPdMeta?.alias || [],
    redirect: safety_45roi8yKNvjNHPdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___en-US",
    path: indexmpYFiqCCmBMeta?.path ?? "/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___ca",
    path: indexmpYFiqCCmBMeta?.path ?? "/ca/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___nl",
    path: indexmpYFiqCCmBMeta?.path ?? "/nl/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___uk",
    path: indexmpYFiqCCmBMeta?.path ?? "/uk/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___de",
    path: indexmpYFiqCCmBMeta?.path ?? "/de/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___mx",
    path: indexmpYFiqCCmBMeta?.path ?? "/mx/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___fr",
    path: indexmpYFiqCCmBMeta?.path ?? "/fr/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpYFiqCCmBMeta?.name ?? "guides___fr-ca",
    path: indexmpYFiqCCmBMeta?.path ?? "/fr-ca/guides",
    meta: indexmpYFiqCCmBMeta || {},
    alias: indexmpYFiqCCmBMeta?.alias || [],
    redirect: indexmpYFiqCCmBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuretRi1CXN6C7Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futuretRi1CXN6C7Meta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuretRi1CXN6C7Meta || {},
    alias: build_45for_45the_45futuretRi1CXN6C7Meta?.alias || [],
    redirect: build_45for_45the_45futuretRi1CXN6C7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsRmkMskb6A6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsRmkMskb6A6Meta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsRmkMskb6A6Meta || {},
    alias: empower_45your_45teamsRmkMskb6A6Meta?.alias || [],
    redirect: empower_45your_45teamsRmkMskb6A6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexBdehBHZ7YzMeta?.path ?? "/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___ca",
    path: indexBdehBHZ7YzMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___nl",
    path: indexBdehBHZ7YzMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___uk",
    path: indexBdehBHZ7YzMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___de",
    path: indexBdehBHZ7YzMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___mx",
    path: indexBdehBHZ7YzMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___fr",
    path: indexBdehBHZ7YzMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdehBHZ7YzMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexBdehBHZ7YzMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexBdehBHZ7YzMeta || {},
    alias: indexBdehBHZ7YzMeta?.alias || [],
    redirect: indexBdehBHZ7YzMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataIUBNDM5kAIMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataIUBNDM5kAIMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataIUBNDM5kAIMeta || {},
    alias: take_45action_45with_45dataIUBNDM5kAIMeta?.alias || [],
    redirect: take_45action_45with_45dataIUBNDM5kAIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jW40k1gxEiMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93jW40k1gxEiMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jW40k1gxEiMeta || {},
    alias: _91slug_93jW40k1gxEiMeta?.alias || [],
    redirect: _91slug_93jW40k1gxEiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indext781wFE9sDMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indext781wFE9sDMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indext781wFE9sDMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indext781wFE9sDMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indext781wFE9sDMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indext781wFE9sDMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indext781wFE9sDMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indext781wFE9sDMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indext781wFE9sDMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indext781wFE9sDMeta || {},
    alias: indext781wFE9sDMeta?.alias || [],
    redirect: indext781wFE9sDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___en-US",
    path: indexxziqXm4cy9Meta?.path ?? "/",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___ca",
    path: indexxziqXm4cy9Meta?.path ?? "/ca",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___nl",
    path: indexxziqXm4cy9Meta?.path ?? "/nl",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___uk",
    path: indexxziqXm4cy9Meta?.path ?? "/uk",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___de",
    path: indexxziqXm4cy9Meta?.path ?? "/de",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___mx",
    path: indexxziqXm4cy9Meta?.path ?? "/mx",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___fr",
    path: indexxziqXm4cy9Meta?.path ?? "/fr",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxziqXm4cy9Meta?.name ?? "index___fr-ca",
    path: indexxziqXm4cy9Meta?.path ?? "/fr-ca",
    meta: indexxziqXm4cy9Meta || {},
    alias: indexxziqXm4cy9Meta?.alias || [],
    redirect: indexxziqXm4cy9Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___en-US",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___ca",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___nl",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___uk",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___de",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___mx",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___fr",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GTOlfWza90Meta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93GTOlfWza90Meta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93GTOlfWza90Meta || {},
    alias: _91slug_93GTOlfWza90Meta?.alias || [],
    redirect: _91slug_93GTOlfWza90Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___ca",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___nl",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___uk",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___de",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___mx",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___fr",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oauA14CUiBMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93oauA14CUiBMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93oauA14CUiBMeta || {},
    alias: _91slug_93oauA14CUiBMeta?.alias || [],
    redirect: _91slug_93oauA14CUiBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___en-US",
    path: indexPiXFageRV8Meta?.path ?? "/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___ca",
    path: indexPiXFageRV8Meta?.path ?? "/ca/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___nl",
    path: indexPiXFageRV8Meta?.path ?? "/nl/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___uk",
    path: indexPiXFageRV8Meta?.path ?? "/uk/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___de",
    path: indexPiXFageRV8Meta?.path ?? "/de/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___mx",
    path: indexPiXFageRV8Meta?.path ?? "/mx/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___fr",
    path: indexPiXFageRV8Meta?.path ?? "/fr/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiXFageRV8Meta?.name ?? "industries___fr-ca",
    path: indexPiXFageRV8Meta?.path ?? "/fr-ca/industries",
    meta: indexPiXFageRV8Meta || {},
    alias: indexPiXFageRV8Meta?.alias || [],
    redirect: indexPiXFageRV8Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___en-US",
    path: cities2JcsQRxf81Meta?.path ?? "/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___ca",
    path: cities2JcsQRxf81Meta?.path ?? "/ca/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___nl",
    path: cities2JcsQRxf81Meta?.path ?? "/nl/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___uk",
    path: cities2JcsQRxf81Meta?.path ?? "/uk/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___de",
    path: cities2JcsQRxf81Meta?.path ?? "/de/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___mx",
    path: cities2JcsQRxf81Meta?.path ?? "/mx/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___fr",
    path: cities2JcsQRxf81Meta?.path ?? "/fr/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities2JcsQRxf81Meta?.name ?? "insights-cities___fr-ca",
    path: cities2JcsQRxf81Meta?.path ?? "/fr-ca/insights/cities",
    meta: cities2JcsQRxf81Meta || {},
    alias: cities2JcsQRxf81Meta?.alias || [],
    redirect: cities2JcsQRxf81Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___en-US",
    path: indexWalzftsh6FMeta?.path ?? "/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___ca",
    path: indexWalzftsh6FMeta?.path ?? "/ca/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___nl",
    path: indexWalzftsh6FMeta?.path ?? "/nl/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___uk",
    path: indexWalzftsh6FMeta?.path ?? "/uk/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___de",
    path: indexWalzftsh6FMeta?.path ?? "/de/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___mx",
    path: indexWalzftsh6FMeta?.path ?? "/mx/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___fr",
    path: indexWalzftsh6FMeta?.path ?? "/fr/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexWalzftsh6FMeta?.name ?? "integrations___fr-ca",
    path: indexWalzftsh6FMeta?.path ?? "/fr-ca/integrations",
    meta: indexWalzftsh6FMeta || {},
    alias: indexWalzftsh6FMeta?.alias || [],
    redirect: indexWalzftsh6FMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___en-US",
    path: indexnwnxu9lbarMeta?.path ?? "/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___ca",
    path: indexnwnxu9lbarMeta?.path ?? "/ca/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___nl",
    path: indexnwnxu9lbarMeta?.path ?? "/nl/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___uk",
    path: indexnwnxu9lbarMeta?.path ?? "/uk/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___de",
    path: indexnwnxu9lbarMeta?.path ?? "/de/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___mx",
    path: indexnwnxu9lbarMeta?.path ?? "/mx/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___fr",
    path: indexnwnxu9lbarMeta?.path ?? "/fr/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwnxu9lbarMeta?.name ?? "invite___fr-ca",
    path: indexnwnxu9lbarMeta?.path ?? "/fr-ca/invite",
    meta: indexnwnxu9lbarMeta || {},
    alias: indexnwnxu9lbarMeta?.alias || [],
    redirect: indexnwnxu9lbarMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeYbWS8S7uHMMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticeYbWS8S7uHMMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeYbWS8S7uHMMeta || {},
    alias: candidate_45privacy_45noticeYbWS8S7uHMMeta?.alias || [],
    redirect: candidate_45privacy_45noticeYbWS8S7uHMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentN9QfsxdvShMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentN9QfsxdvShMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentN9QfsxdvShMeta || {},
    alias: cookie_45consentN9QfsxdvShMeta?.alias || [],
    redirect: cookie_45consentN9QfsxdvShMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyGAWAwzmbcIMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyGAWAwzmbcIMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyGAWAwzmbcIMeta || {},
    alias: cookie_45policyGAWAwzmbcIMeta?.alias || [],
    redirect: cookie_45policyGAWAwzmbcIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumgnDOBWX6PtMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumgnDOBWX6PtMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumgnDOBWX6PtMeta || {},
    alias: data_45protection_45addendumgnDOBWX6PtMeta?.alias || [],
    redirect: data_45protection_45addendumgnDOBWX6PtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsAMhSTQoCLDMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsAMhSTQoCLDMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsAMhSTQoCLDMeta || {},
    alias: express_45order_45termsAMhSTQoCLDMeta?.alias || [],
    redirect: express_45order_45termsAMhSTQoCLDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantykzgFhBLJelMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantykzgFhBLJelMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantykzgFhBLJelMeta || {},
    alias: hardware_45warrantykzgFhBLJelMeta?.alias || [],
    redirect: hardware_45warrantykzgFhBLJelMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slaYmYTFKPSn1Meta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slaYmYTFKPSn1Meta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slaYmYTFKPSn1Meta || {},
    alias: hosted_45software_45slaYmYTFKPSn1Meta?.alias || [],
    redirect: hosted_45software_45slaYmYTFKPSn1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductSD7RSt7B5jMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductSD7RSt7B5jMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductSD7RSt7B5jMeta || {},
    alias: partner_45code_45of_45conductSD7RSt7B5jMeta?.alias || [],
    redirect: partner_45code_45of_45conductSD7RSt7B5jMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceoOCWbHNcwRMeta || {},
    alias: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceoOCWbHNcwRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___en-US",
    path: privacyhiyZl0YCJPMeta?.path ?? "/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___ca",
    path: privacyhiyZl0YCJPMeta?.path ?? "/ca/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___nl",
    path: privacyhiyZl0YCJPMeta?.path ?? "/nl/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___uk",
    path: privacyhiyZl0YCJPMeta?.path ?? "/uk/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___de",
    path: privacyhiyZl0YCJPMeta?.path ?? "/de/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___mx",
    path: privacyhiyZl0YCJPMeta?.path ?? "/mx/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___fr",
    path: privacyhiyZl0YCJPMeta?.path ?? "/fr/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyhiyZl0YCJPMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyhiyZl0YCJPMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyhiyZl0YCJPMeta || {},
    alias: privacyhiyZl0YCJPMeta?.alias || [],
    redirect: privacyhiyZl0YCJPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceqCtcJLThhpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata50ZukeWhwCMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdata50ZukeWhwCMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdata50ZukeWhwCMeta || {},
    alias: requestingcustomerdata50ZukeWhwCMeta?.alias || [],
    redirect: requestingcustomerdata50ZukeWhwCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___en-US",
    path: security2HWjLsurwiMeta?.path ?? "/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___ca",
    path: security2HWjLsurwiMeta?.path ?? "/ca/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___nl",
    path: security2HWjLsurwiMeta?.path ?? "/nl/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___uk",
    path: security2HWjLsurwiMeta?.path ?? "/uk/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___de",
    path: security2HWjLsurwiMeta?.path ?? "/de/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___mx",
    path: security2HWjLsurwiMeta?.path ?? "/mx/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___fr",
    path: security2HWjLsurwiMeta?.path ?? "/fr/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security2HWjLsurwiMeta?.name ?? "legal-security___fr-ca",
    path: security2HWjLsurwiMeta?.path ?? "/fr-ca/legal/security",
    meta: security2HWjLsurwiMeta || {},
    alias: security2HWjLsurwiMeta?.alias || [],
    redirect: security2HWjLsurwiMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportFJcuvFJTHpMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportFJcuvFJTHpMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportFJcuvFJTHpMeta || {},
    alias: transparencyreportFJcuvFJTHpMeta?.alias || [],
    redirect: transparencyreportFJcuvFJTHpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicevFKnzfDi9LMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45servicevFKnzfDi9LMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicevFKnzfDi9LMeta || {},
    alias: website_45terms_45of_45servicevFKnzfDi9LMeta?.alias || [],
    redirect: website_45terms_45of_45servicevFKnzfDi9LMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesh8cUPr7vfGMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesh8cUPr7vfGMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesh8cUPr7vfGMeta || {},
    alias: your_45privacy_45choicesh8cUPr7vfGMeta?.alias || [],
    redirect: your_45privacy_45choicesh8cUPr7vfGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___en-US",
    path: pricingzNT7gaKwcMMeta?.path ?? "/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___ca",
    path: pricingzNT7gaKwcMMeta?.path ?? "/ca/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___nl",
    path: pricingzNT7gaKwcMMeta?.path ?? "/nl/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___uk",
    path: pricingzNT7gaKwcMMeta?.path ?? "/uk/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___de",
    path: pricingzNT7gaKwcMMeta?.path ?? "/de/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___mx",
    path: pricingzNT7gaKwcMMeta?.path ?? "/mx/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___fr",
    path: pricingzNT7gaKwcMMeta?.path ?? "/fr/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingzNT7gaKwcMMeta?.name ?? "page-pricing___fr-ca",
    path: pricingzNT7gaKwcMMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingzNT7gaKwcMMeta || {},
    alias: pricingzNT7gaKwcMMeta?.alias || [],
    redirect: pricingzNT7gaKwcMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936fIV8hMLnVMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_936fIV8hMLnVMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_936fIV8hMLnVMeta || {},
    alias: _91slug_936fIV8hMLnVMeta?.alias || [],
    redirect: _91slug_936fIV8hMLnVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___ca",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___nl",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___uk",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___de",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___mx",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___fr",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YUTiSN6viRMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93YUTiSN6viRMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93YUTiSN6viRMeta || {},
    alias: _91slug_93YUTiSN6viRMeta?.alias || [],
    redirect: _91slug_93YUTiSN6viRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrHLstnGW3PMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingrHLstnGW3PMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingrHLstnGW3PMeta || {},
    alias: asset_45trackingrHLstnGW3PMeta?.alias || [],
    redirect: asset_45trackingrHLstnGW3PMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSFsBZcSanLMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsSFsBZcSanLMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsSFsBZcSanLMeta || {},
    alias: best_45dash_45camsSFsBZcSanLMeta?.alias || [],
    redirect: best_45dash_45camsSFsBZcSanLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___en-US",
    path: construction0jKcMjk52TMeta?.path ?? "/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___ca",
    path: construction0jKcMjk52TMeta?.path ?? "/ca/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___nl",
    path: construction0jKcMjk52TMeta?.path ?? "/nl/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___uk",
    path: construction0jKcMjk52TMeta?.path ?? "/uk/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___de",
    path: construction0jKcMjk52TMeta?.path ?? "/de/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___mx",
    path: construction0jKcMjk52TMeta?.path ?? "/mx/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___fr",
    path: construction0jKcMjk52TMeta?.path ?? "/fr/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction0jKcMjk52TMeta?.name ?? "pages-construction___fr-ca",
    path: construction0jKcMjk52TMeta?.path ?? "/fr-ca/pages/construction",
    meta: construction0jKcMjk52TMeta || {},
    alias: construction0jKcMjk52TMeta?.alias || [],
    redirect: construction0jKcMjk52TMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___en-US",
    path: eversource3P95RCijgoMeta?.path ?? "/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___ca",
    path: eversource3P95RCijgoMeta?.path ?? "/ca/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___nl",
    path: eversource3P95RCijgoMeta?.path ?? "/nl/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___uk",
    path: eversource3P95RCijgoMeta?.path ?? "/uk/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___de",
    path: eversource3P95RCijgoMeta?.path ?? "/de/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___mx",
    path: eversource3P95RCijgoMeta?.path ?? "/mx/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___fr",
    path: eversource3P95RCijgoMeta?.path ?? "/fr/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversource3P95RCijgoMeta?.name ?? "pages-eversource___fr-ca",
    path: eversource3P95RCijgoMeta?.path ?? "/fr-ca/pages/eversource",
    meta: eversource3P95RCijgoMeta || {},
    alias: eversource3P95RCijgoMeta?.alias || [],
    redirect: eversource3P95RCijgoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45management0nKQWvUvYpMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45management0nKQWvUvYpMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45management0nKQWvUvYpMeta || {},
    alias: fleet_45management0nKQWvUvYpMeta?.alias || [],
    redirect: fleet_45management0nKQWvUvYpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___en-US",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___ca",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/ca/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___nl",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/nl/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___uk",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/uk/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___de",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/de/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___mx",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/mx/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___fr",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/fr/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksU9xbHwaiOvMeta?.name ?? "pages-groundworks___fr-ca",
    path: groundworksU9xbHwaiOvMeta?.path ?? "/fr-ca/pages/groundworks",
    meta: groundworksU9xbHwaiOvMeta || {},
    alias: groundworksU9xbHwaiOvMeta?.alias || [],
    redirect: groundworksU9xbHwaiOvMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___en-US",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___ca",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___nl",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___uk",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___de",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/de/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___mx",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___fr",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidec4dId7HQ5cMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwidec4dId7HQ5cMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwidec4dId7HQ5cMeta || {},
    alias: nationwidec4dId7HQ5cMeta?.alias || [],
    redirect: nationwidec4dId7HQ5cMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAOOtEFrHTmMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingAOOtEFrHTmMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingAOOtEFrHTmMeta || {},
    alias: safety_45coachingAOOtEFrHTmMeta?.alias || [],
    redirect: safety_45coachingAOOtEFrHTmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___en-US",
    path: unifirstAX4RkU6kANMeta?.path ?? "/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___ca",
    path: unifirstAX4RkU6kANMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___nl",
    path: unifirstAX4RkU6kANMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___uk",
    path: unifirstAX4RkU6kANMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___de",
    path: unifirstAX4RkU6kANMeta?.path ?? "/de/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___mx",
    path: unifirstAX4RkU6kANMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___fr",
    path: unifirstAX4RkU6kANMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstAX4RkU6kANMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstAX4RkU6kANMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstAX4RkU6kANMeta || {},
    alias: unifirstAX4RkU6kANMeta?.alias || [],
    redirect: unifirstAX4RkU6kANMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___en-US",
    path: pricingCnJrzlVXk4Meta?.path ?? "/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___ca",
    path: pricingCnJrzlVXk4Meta?.path ?? "/ca/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___nl",
    path: pricingCnJrzlVXk4Meta?.path ?? "/nl/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___uk",
    path: pricingCnJrzlVXk4Meta?.path ?? "/uk/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___de",
    path: pricingCnJrzlVXk4Meta?.path ?? "/de/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___mx",
    path: pricingCnJrzlVXk4Meta?.path ?? "/mx/prueba-gratis",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___fr",
    path: pricingCnJrzlVXk4Meta?.path ?? "/fr/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingCnJrzlVXk4Meta?.name ?? "pricing___fr-ca",
    path: pricingCnJrzlVXk4Meta?.path ?? "/fr-ca/pricing",
    meta: pricingCnJrzlVXk4Meta || {},
    alias: pricingCnJrzlVXk4Meta?.alias || [],
    redirect: pricingCnJrzlVXk4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CqYwDG1ZgfMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93CqYwDG1ZgfMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93CqYwDG1ZgfMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93CqYwDG1ZgfMeta?.alias || [],
    redirect: _91slug_93CqYwDG1ZgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___en-US",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___ca",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/ca/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___nl",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/nl/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___uk",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/uk/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___de",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/de/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___mx",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/mx/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___fr",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/fr/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R1hx2QXBsIMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93R1hx2QXBsIMeta?.path ?? "/fr-ca/products/:slug()",
    meta: { ...(_91slug_93R1hx2QXBsIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93R1hx2QXBsIMeta?.alias || [],
    redirect: _91slug_93R1hx2QXBsIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingvyijYDMDgDMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingvyijYDMDgDMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingvyijYDMDgDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingvyijYDMDgDMeta?.alias || [],
    redirect: asset_45trackingvyijYDMDgDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingb90LJxYcr4Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingb90LJxYcr4Meta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingb90LJxYcr4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingb90LJxYcr4Meta?.alias || [],
    redirect: equipment_45trackingb90LJxYcr4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringXrBwi8pMzoMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringXrBwi8pMzoMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringXrBwi8pMzoMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringXrBwi8pMzoMeta?.alias || [],
    redirect: reefer_45monitoringXrBwi8pMzoMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerseNWU93HQ0sMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailerseNWU93HQ0sMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailerseNWU93HQ0sMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailerseNWU93HQ0sMeta?.alias || [],
    redirect: smart_45trailerseNWU93HQ0sMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghWZRN2wXqAMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackinghWZRN2wXqAMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackinghWZRN2wXqAMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackinghWZRN2wXqAMeta?.alias || [],
    redirect: trailer_45trackinghWZRN2wXqAMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___en-US",
    path: indexDfDghf7dIQMeta?.path ?? "/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___ca",
    path: indexDfDghf7dIQMeta?.path ?? "/ca/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___nl",
    path: indexDfDghf7dIQMeta?.path ?? "/nl/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___uk",
    path: indexDfDghf7dIQMeta?.path ?? "/uk/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___de",
    path: indexDfDghf7dIQMeta?.path ?? "/de/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___mx",
    path: indexDfDghf7dIQMeta?.path ?? "/mx/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___fr",
    path: indexDfDghf7dIQMeta?.path ?? "/fr/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfDghf7dIQMeta?.name ?? "products___fr-ca",
    path: indexDfDghf7dIQMeta?.path ?? "/fr-ca/products",
    meta: { ...(indexDfDghf7dIQMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexDfDghf7dIQMeta?.alias || [],
    redirect: indexDfDghf7dIQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___ca",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/ca/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___nl",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/nl/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___uk",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/uk/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___de",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/de/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___mx",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/mx/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___fr",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/fr/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aaM0tS5zaMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_930aaM0tS5zaMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: { ...(_91slug_930aaM0tS5zaMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_930aaM0tS5zaMeta?.alias || [],
    redirect: _91slug_930aaM0tS5zaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___en-US",
    path: index2HfJ5dYvxkMeta?.path ?? "/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___ca",
    path: index2HfJ5dYvxkMeta?.path ?? "/ca/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___nl",
    path: index2HfJ5dYvxkMeta?.path ?? "/nl/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___uk",
    path: index2HfJ5dYvxkMeta?.path ?? "/uk/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___de",
    path: index2HfJ5dYvxkMeta?.path ?? "/de/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___mx",
    path: index2HfJ5dYvxkMeta?.path ?? "/mx/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___fr",
    path: index2HfJ5dYvxkMeta?.path ?? "/fr/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index2HfJ5dYvxkMeta?.name ?? "products-models___fr-ca",
    path: index2HfJ5dYvxkMeta?.path ?? "/fr-ca/products/models",
    meta: { ...(index2HfJ5dYvxkMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index2HfJ5dYvxkMeta?.alias || [],
    redirect: index2HfJ5dYvxkMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfR6zu11Z2SMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingfR6zu11Z2SMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingfR6zu11Z2SMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingfR6zu11Z2SMeta?.alias || [],
    redirect: gps_45fleet_45trackingfR6zu11Z2SMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___en-US",
    path: indexOePakGekwDMeta?.path ?? "/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___ca",
    path: indexOePakGekwDMeta?.path ?? "/ca/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___nl",
    path: indexOePakGekwDMeta?.path ?? "/nl/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___uk",
    path: indexOePakGekwDMeta?.path ?? "/uk/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___de",
    path: indexOePakGekwDMeta?.path ?? "/de/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___mx",
    path: indexOePakGekwDMeta?.path ?? "/mx/products/flota",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___fr",
    path: indexOePakGekwDMeta?.path ?? "/fr/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexOePakGekwDMeta?.name ?? "products-telematics___fr-ca",
    path: indexOePakGekwDMeta?.path ?? "/fr-ca/products/telematics",
    meta: { ...(indexOePakGekwDMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexOePakGekwDMeta?.alias || [],
    redirect: indexOePakGekwDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: racingHcrQjIsg6dMeta?.name ?? "racing___en-US",
    path: racingHcrQjIsg6dMeta?.path ?? "/racing",
    meta: racingHcrQjIsg6dMeta || {},
    alias: racingHcrQjIsg6dMeta?.alias || [],
    redirect: racingHcrQjIsg6dMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___en-US",
    path: referralsZLdJtDgR27Meta?.path ?? "/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___ca",
    path: referralsZLdJtDgR27Meta?.path ?? "/ca/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___nl",
    path: referralsZLdJtDgR27Meta?.path ?? "/nl/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___uk",
    path: referralsZLdJtDgR27Meta?.path ?? "/uk/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___de",
    path: referralsZLdJtDgR27Meta?.path ?? "/de/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___mx",
    path: referralsZLdJtDgR27Meta?.path ?? "/mx/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___fr",
    path: referralsZLdJtDgR27Meta?.path ?? "/fr/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsZLdJtDgR27Meta?.name ?? "referrals___fr-ca",
    path: referralsZLdJtDgR27Meta?.path ?? "/fr-ca/referrals",
    meta: referralsZLdJtDgR27Meta || {},
    alias: referralsZLdJtDgR27Meta?.alias || [],
    redirect: referralsZLdJtDgR27Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___en-US",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___ca",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___nl",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___uk",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___de",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___mx",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___fr",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932zM96pw9PuMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_932zM96pw9PuMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_932zM96pw9PuMeta || {},
    alias: _91slug_932zM96pw9PuMeta?.alias || [],
    redirect: _91slug_932zM96pw9PuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___ca",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___nl",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___uk",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___de",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___mx",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___fr",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets5InFs8R5H0Meta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assets5InFs8R5H0Meta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assets5InFs8R5H0Meta || {},
    alias: brand_45assets5InFs8R5H0Meta?.alias || [],
    redirect: brand_45assets5InFs8R5H0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AMi4S0auGyMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93AMi4S0auGyMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93AMi4S0auGyMeta || {},
    alias: _91slug_93AMi4S0auGyMeta?.alias || [],
    redirect: _91slug_93AMi4S0auGyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center7LhRaVaTPMMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45center7LhRaVaTPMMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45center7LhRaVaTPMMeta || {},
    alias: email_45preferences_45center7LhRaVaTPMMeta?.alias || [],
    redirect: email_45preferences_45center7LhRaVaTPMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___en-US",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___ca",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___nl",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/nl/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___uk",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/uk/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___de",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/de/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___mx",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/mx/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___fr",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/fr/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeYwqQY7zpgfMeta?.name ?? "resources-content-one-click-unsubscribe___fr-ca",
    path: one_45click_45unsubscribeYwqQY7zpgfMeta?.path ?? "/fr-ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeYwqQY7zpgfMeta || {},
    alias: one_45click_45unsubscribeYwqQY7zpgfMeta?.alias || [],
    redirect: one_45click_45unsubscribeYwqQY7zpgfMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GM4QdyYHZpMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93GM4QdyYHZpMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93GM4QdyYHZpMeta || {},
    alias: _91slug_93GM4QdyYHZpMeta?.alias || [],
    redirect: _91slug_93GM4QdyYHZpMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930WERZpUlVIMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_930WERZpUlVIMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_930WERZpUlVIMeta || {},
    alias: _91slug_930WERZpUlVIMeta?.alias || [],
    redirect: _91slug_930WERZpUlVIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___en-US",
    path: index2mjwtypTIgMeta?.path ?? "/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___ca",
    path: index2mjwtypTIgMeta?.path ?? "/ca/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___nl",
    path: index2mjwtypTIgMeta?.path ?? "/nl/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___uk",
    path: index2mjwtypTIgMeta?.path ?? "/uk/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___de",
    path: index2mjwtypTIgMeta?.path ?? "/de/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___mx",
    path: index2mjwtypTIgMeta?.path ?? "/mx/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___fr",
    path: index2mjwtypTIgMeta?.path ?? "/fr/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2mjwtypTIgMeta?.name ?? "resources-experts___fr-ca",
    path: index2mjwtypTIgMeta?.path ?? "/fr-ca/resources/experts",
    meta: index2mjwtypTIgMeta || {},
    alias: index2mjwtypTIgMeta?.alias || [],
    redirect: index2mjwtypTIgMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___en-US",
    path: index2sdZTub637Meta?.path ?? "/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___ca",
    path: index2sdZTub637Meta?.path ?? "/ca/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___nl",
    path: index2sdZTub637Meta?.path ?? "/nl/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___uk",
    path: index2sdZTub637Meta?.path ?? "/uk/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___de",
    path: index2sdZTub637Meta?.path ?? "/de/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___mx",
    path: index2sdZTub637Meta?.path ?? "/mx/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___fr",
    path: index2sdZTub637Meta?.path ?? "/fr/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index2sdZTub637Meta?.name ?? "resources___fr-ca",
    path: index2sdZTub637Meta?.path ?? "/fr-ca/resources",
    meta: index2sdZTub637Meta || {},
    alias: index2sdZTub637Meta?.alias || [],
    redirect: index2sdZTub637Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932uEKNKbvsDMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_932uEKNKbvsDMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_932uEKNKbvsDMeta || {},
    alias: _91slug_932uEKNKbvsDMeta?.alias || [],
    redirect: _91slug_932uEKNKbvsDMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93swn2n8IfQqMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93swn2n8IfQqMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93swn2n8IfQqMeta || {},
    alias: _91slug_93swn2n8IfQqMeta?.alias || [],
    redirect: _91slug_93swn2n8IfQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___en-US",
    path: indexrNcJybvDtIMeta?.path ?? "/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___ca",
    path: indexrNcJybvDtIMeta?.path ?? "/ca/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___nl",
    path: indexrNcJybvDtIMeta?.path ?? "/nl/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___uk",
    path: indexrNcJybvDtIMeta?.path ?? "/uk/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___de",
    path: indexrNcJybvDtIMeta?.path ?? "/de/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___mx",
    path: indexrNcJybvDtIMeta?.path ?? "/mx/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___fr",
    path: indexrNcJybvDtIMeta?.path ?? "/fr/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexrNcJybvDtIMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexrNcJybvDtIMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexrNcJybvDtIMeta || {},
    alias: indexrNcJybvDtIMeta?.alias || [],
    redirect: indexrNcJybvDtIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara436N4EwSEKMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsara436N4EwSEKMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara436N4EwSEKMeta || {},
    alias: build_45with_45samsara436N4EwSEKMeta?.alias || [],
    redirect: build_45with_45samsara436N4EwSEKMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsX2QAu6br6OMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsX2QAu6br6OMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsX2QAu6br6OMeta || {},
    alias: evolve_45insurance_45programsX2QAu6br6OMeta?.alias || [],
    redirect: evolve_45insurance_45programsX2QAu6br6OMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___en-US",
    path: index2iJ5xxcKHLMeta?.path ?? "/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___ca",
    path: index2iJ5xxcKHLMeta?.path ?? "/ca/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___nl",
    path: index2iJ5xxcKHLMeta?.path ?? "/nl/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___uk",
    path: index2iJ5xxcKHLMeta?.path ?? "/uk/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___de",
    path: index2iJ5xxcKHLMeta?.path ?? "/de/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___mx",
    path: index2iJ5xxcKHLMeta?.path ?? "/mx/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___fr",
    path: index2iJ5xxcKHLMeta?.path ?? "/fr/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index2iJ5xxcKHLMeta?.name ?? "resources-partner-programs___fr-ca",
    path: index2iJ5xxcKHLMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: index2iJ5xxcKHLMeta || {},
    alias: index2iJ5xxcKHLMeta?.alias || [],
    redirect: index2iJ5xxcKHLMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesnV5ajUt9dVMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesnV5ajUt9dVMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesnV5ajUt9dVMeta || {},
    alias: provide_45servicesnV5ajUt9dVMeta?.alias || [],
    redirect: provide_45servicesnV5ajUt9dVMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraO9i85dt7A4Meta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaraO9i85dt7A4Meta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraO9i85dt7A4Meta || {},
    alias: sell_45samsaraO9i85dt7A4Meta?.alias || [],
    redirect: sell_45samsaraO9i85dt7A4Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___en-US",
    path: tour3tsI32NwMyMeta?.path ?? "/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___ca",
    path: tour3tsI32NwMyMeta?.path ?? "/ca/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___nl",
    path: tour3tsI32NwMyMeta?.path ?? "/nl/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___uk",
    path: tour3tsI32NwMyMeta?.path ?? "/uk/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___de",
    path: tour3tsI32NwMyMeta?.path ?? "/de/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___mx",
    path: tour3tsI32NwMyMeta?.path ?? "/mx/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___fr",
    path: tour3tsI32NwMyMeta?.path ?? "/fr/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour3tsI32NwMyMeta?.name ?? "resources-tour___fr-ca",
    path: tour3tsI32NwMyMeta?.path ?? "/fr-ca/resources/tour",
    meta: tour3tsI32NwMyMeta || {},
    alias: tour3tsI32NwMyMeta?.alias || [],
    redirect: tour3tsI32NwMyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___en-US",
    path: videosljwwPGOzwmMeta?.path ?? "/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___ca",
    path: videosljwwPGOzwmMeta?.path ?? "/ca/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___nl",
    path: videosljwwPGOzwmMeta?.path ?? "/nl/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___uk",
    path: videosljwwPGOzwmMeta?.path ?? "/uk/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___de",
    path: videosljwwPGOzwmMeta?.path ?? "/de/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___mx",
    path: videosljwwPGOzwmMeta?.path ?? "/mx/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___fr",
    path: videosljwwPGOzwmMeta?.path ?? "/fr/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosljwwPGOzwmMeta?.name ?? "resources-videos___fr-ca",
    path: videosljwwPGOzwmMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosljwwPGOzwmMeta || {},
    alias: videosljwwPGOzwmMeta?.alias || [],
    redirect: videosljwwPGOzwmMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___en-US",
    path: indexEgxzbkphQqMeta?.path ?? "/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___ca",
    path: indexEgxzbkphQqMeta?.path ?? "/ca/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___nl",
    path: indexEgxzbkphQqMeta?.path ?? "/nl/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___uk",
    path: indexEgxzbkphQqMeta?.path ?? "/uk/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___de",
    path: indexEgxzbkphQqMeta?.path ?? "/de/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___mx",
    path: indexEgxzbkphQqMeta?.path ?? "/mx/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___fr",
    path: indexEgxzbkphQqMeta?.path ?? "/fr/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgxzbkphQqMeta?.name ?? "roi-calculator___fr-ca",
    path: indexEgxzbkphQqMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexEgxzbkphQqMeta || {},
    alias: indexEgxzbkphQqMeta?.alias || [],
    redirect: indexEgxzbkphQqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesRE7v9pbYvQMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesRE7v9pbYvQMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesRE7v9pbYvQMeta || {},
    alias: samsara_45venturesRE7v9pbYvQMeta?.alias || [],
    redirect: samsara_45venturesRE7v9pbYvQMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___en-US",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___ca",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/ca/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___nl",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/nl/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___uk",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/uk/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___de",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/de/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___mx",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/mx/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___fr",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/fr/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxJ1X8OtLWMMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitxJ1X8OtLWMMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitxJ1X8OtLWMMeta || {},
    alias: security_45summitxJ1X8OtLWMMeta?.alias || [],
    redirect: security_45summitxJ1X8OtLWMMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___en-US",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___ca",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/ca/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___nl",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/nl/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___uk",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/uk/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___de",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/de/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___mx",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/mx/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___fr",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/fr/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y0btjjYsc1Meta?.name ?? "solutions-prefix-slug___fr-ca",
    path: _91slug_93y0btjjYsc1Meta?.path ?? "/fr-ca/solutions/:prefix()/:slug()",
    meta: _91slug_93y0btjjYsc1Meta || {},
    alias: _91slug_93y0btjjYsc1Meta?.alias || [],
    redirect: _91slug_93y0btjjYsc1Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___en-US",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___ca",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___nl",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___uk",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___de",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___mx",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___fr",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aRgkVkCgx3Meta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93aRgkVkCgx3Meta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93aRgkVkCgx3Meta || {},
    alias: _91slug_93aRgkVkCgx3Meta?.alias || [],
    redirect: _91slug_93aRgkVkCgx3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___en-US",
    path: indexwIGlrDEgeRMeta?.path ?? "/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___ca",
    path: indexwIGlrDEgeRMeta?.path ?? "/ca/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___nl",
    path: indexwIGlrDEgeRMeta?.path ?? "/nl/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___uk",
    path: indexwIGlrDEgeRMeta?.path ?? "/uk/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___de",
    path: indexwIGlrDEgeRMeta?.path ?? "/de/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___mx",
    path: indexwIGlrDEgeRMeta?.path ?? "/mx/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___fr",
    path: indexwIGlrDEgeRMeta?.path ?? "/fr/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexwIGlrDEgeRMeta?.name ?? "solutions___fr-ca",
    path: indexwIGlrDEgeRMeta?.path ?? "/fr-ca/solutions",
    meta: indexwIGlrDEgeRMeta || {},
    alias: indexwIGlrDEgeRMeta?.alias || [],
    redirect: indexwIGlrDEgeRMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___en-US",
    path: security0SaSpRKxqaMeta?.path ?? "/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___ca",
    path: security0SaSpRKxqaMeta?.path ?? "/ca/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___nl",
    path: security0SaSpRKxqaMeta?.path ?? "/nl/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___uk",
    path: security0SaSpRKxqaMeta?.path ?? "/uk/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___de",
    path: security0SaSpRKxqaMeta?.path ?? "/de/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___mx",
    path: security0SaSpRKxqaMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___fr",
    path: security0SaSpRKxqaMeta?.path ?? "/fr/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security0SaSpRKxqaMeta?.name ?? "solutions-security___fr-ca",
    path: security0SaSpRKxqaMeta?.path ?? "/fr-ca/solutions/security",
    meta: security0SaSpRKxqaMeta || {},
    alias: security0SaSpRKxqaMeta?.alias || [],
    redirect: security0SaSpRKxqaMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___en-US",
    path: space4MnNqA6AtsMeta?.path ?? "/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___ca",
    path: space4MnNqA6AtsMeta?.path ?? "/ca/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___nl",
    path: space4MnNqA6AtsMeta?.path ?? "/nl/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___uk",
    path: space4MnNqA6AtsMeta?.path ?? "/uk/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___de",
    path: space4MnNqA6AtsMeta?.path ?? "/de/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___mx",
    path: space4MnNqA6AtsMeta?.path ?? "/mx/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___fr",
    path: space4MnNqA6AtsMeta?.path ?? "/fr/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space4MnNqA6AtsMeta?.name ?? "space___fr-ca",
    path: space4MnNqA6AtsMeta?.path ?? "/fr-ca/space",
    meta: space4MnNqA6AtsMeta || {},
    alias: space4MnNqA6AtsMeta?.alias || [],
    redirect: space4MnNqA6AtsMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___en-US",
    path: sparkVudL7sdBlrMeta?.path ?? "/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___ca",
    path: sparkVudL7sdBlrMeta?.path ?? "/ca/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___nl",
    path: sparkVudL7sdBlrMeta?.path ?? "/nl/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___uk",
    path: sparkVudL7sdBlrMeta?.path ?? "/uk/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___de",
    path: sparkVudL7sdBlrMeta?.path ?? "/de/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___mx",
    path: sparkVudL7sdBlrMeta?.path ?? "/mx/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___fr",
    path: sparkVudL7sdBlrMeta?.path ?? "/fr/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkVudL7sdBlrMeta?.name ?? "spark___fr-ca",
    path: sparkVudL7sdBlrMeta?.path ?? "/fr-ca/spark",
    meta: sparkVudL7sdBlrMeta || {},
    alias: sparkVudL7sdBlrMeta?.alias || [],
    redirect: sparkVudL7sdBlrMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___ca",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___nl",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___uk",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___de",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___mx",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___fr",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kaxQe5BNKJMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93kaxQe5BNKJMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93kaxQe5BNKJMeta || {},
    alias: _91slug_93kaxQe5BNKJMeta?.alias || [],
    redirect: _91slug_93kaxQe5BNKJMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LPfRXeU8fPMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93LPfRXeU8fPMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93LPfRXeU8fPMeta || {},
    alias: _91slug_93LPfRXeU8fPMeta?.alias || [],
    redirect: _91slug_93LPfRXeU8fPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___en-US",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___ca",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___nl",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___uk",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___de",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___mx",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___fr",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVqnUxYDSCMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93BVqnUxYDSCMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93BVqnUxYDSCMeta || {},
    alias: _91slug_93BVqnUxYDSCMeta?.alias || [],
    redirect: _91slug_93BVqnUxYDSCMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy72vLrX6lFjMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policy72vLrX6lFjMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policy72vLrX6lFjMeta || {},
    alias: apps_45privacy_45policy72vLrX6lFjMeta?.alias || [],
    redirect: apps_45privacy_45policy72vLrX6lFjMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqmZ62OMKMcYMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqmZ62OMKMcYMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqmZ62OMKMcYMeta || {},
    alias: customer_45referrals_45faqmZ62OMKMcYMeta?.alias || [],
    redirect: customer_45referrals_45faqmZ62OMKMcYMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk6sE741JZqMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishk6sE741JZqMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishk6sE741JZqMeta || {},
    alias: driver_45training_45spanishk6sE741JZqMeta?.alias || [],
    redirect: driver_45training_45spanishk6sE741JZqMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingEIcPeocO4gMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingEIcPeocO4gMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingEIcPeocO4gMeta || {},
    alias: driver_45trainingEIcPeocO4gMeta?.alias || [],
    redirect: driver_45trainingEIcPeocO4gMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___en-US",
    path: indexMC4i2bb8MEMeta?.path ?? "/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___ca",
    path: indexMC4i2bb8MEMeta?.path ?? "/ca/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___nl",
    path: indexMC4i2bb8MEMeta?.path ?? "/nl/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___uk",
    path: indexMC4i2bb8MEMeta?.path ?? "/uk/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___de",
    path: indexMC4i2bb8MEMeta?.path ?? "/de/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___mx",
    path: indexMC4i2bb8MEMeta?.path ?? "/mx/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___fr",
    path: indexMC4i2bb8MEMeta?.path ?? "/fr/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC4i2bb8MEMeta?.name ?? "support___fr-ca",
    path: indexMC4i2bb8MEMeta?.path ?? "/fr-ca/support",
    meta: indexMC4i2bb8MEMeta || {},
    alias: indexMC4i2bb8MEMeta?.alias || [],
    redirect: indexMC4i2bb8MEMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___en-US",
    path: industrialRKCRVfAbcwMeta?.path ?? "/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___ca",
    path: industrialRKCRVfAbcwMeta?.path ?? "/ca/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___nl",
    path: industrialRKCRVfAbcwMeta?.path ?? "/nl/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___uk",
    path: industrialRKCRVfAbcwMeta?.path ?? "/uk/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___de",
    path: industrialRKCRVfAbcwMeta?.path ?? "/de/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___mx",
    path: industrialRKCRVfAbcwMeta?.path ?? "/mx/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___fr",
    path: industrialRKCRVfAbcwMeta?.path ?? "/fr/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialRKCRVfAbcwMeta?.name ?? "support-industrial___fr-ca",
    path: industrialRKCRVfAbcwMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialRKCRVfAbcwMeta || {},
    alias: industrialRKCRVfAbcwMeta?.alias || [],
    redirect: industrialRKCRVfAbcwMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementp17E475rmtMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementp17E475rmtMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementp17E475rmtMeta || {},
    alias: modern_45slavery_45statementp17E475rmtMeta?.alias || [],
    redirect: modern_45slavery_45statementp17E475rmtMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8aifuR1U8RMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policy8aifuR1U8RMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy8aifuR1U8RMeta || {},
    alias: apps_45privacy_45policy8aifuR1U8RMeta?.alias || [],
    redirect: apps_45privacy_45policy8aifuR1U8RMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___en-US",
    path: indexjDqCVMzRc6Meta?.path ?? "/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___ca",
    path: indexjDqCVMzRc6Meta?.path ?? "/ca/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___nl",
    path: indexjDqCVMzRc6Meta?.path ?? "/nl/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___uk",
    path: indexjDqCVMzRc6Meta?.path ?? "/uk/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___de",
    path: indexjDqCVMzRc6Meta?.path ?? "/de/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___mx",
    path: indexjDqCVMzRc6Meta?.path ?? "/mx/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___fr",
    path: indexjDqCVMzRc6Meta?.path ?? "/fr/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexjDqCVMzRc6Meta?.name ?? "support-privacy___fr-ca",
    path: indexjDqCVMzRc6Meta?.path ?? "/fr-ca/support/privacy",
    meta: indexjDqCVMzRc6Meta || {},
    alias: indexjDqCVMzRc6Meta?.alias || [],
    redirect: indexjDqCVMzRc6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___ca",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___nl",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___uk",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___de",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___mx",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___fr",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureserdquVgFQ3Meta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featureserdquVgFQ3Meta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featureserdquVgFQ3Meta || {},
    alias: special_45featureserdquVgFQ3Meta?.alias || [],
    redirect: special_45featureserdquVgFQ3Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listMzVuY5Us5bMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listMzVuY5Us5bMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listMzVuY5Us5bMeta || {},
    alias: subprocessor_45listMzVuY5Us5bMeta?.alias || [],
    redirect: subprocessor_45listMzVuY5Us5bMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesyzpavNFkL0Meta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesyzpavNFkL0Meta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesyzpavNFkL0Meta || {},
    alias: support_45kb_45articlesyzpavNFkL0Meta?.alias || [],
    redirect: support_45kb_45articlesyzpavNFkL0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesyzpavNFkL0Meta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesyzpavNFkL0Meta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesyzpavNFkL0Meta || {},
    alias: support_45kb_45articlesyzpavNFkL0Meta?.alias || [],
    redirect: support_45kb_45articlesyzpavNFkL0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesyzpavNFkL0Meta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesyzpavNFkL0Meta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesyzpavNFkL0Meta || {},
    alias: support_45kb_45articlesyzpavNFkL0Meta?.alias || [],
    redirect: support_45kb_45articlesyzpavNFkL0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesyzpavNFkL0Meta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesyzpavNFkL0Meta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesyzpavNFkL0Meta || {},
    alias: support_45kb_45articlesyzpavNFkL0Meta?.alias || [],
    redirect: support_45kb_45articlesyzpavNFkL0Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45page7rOZNfahrPMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45page7rOZNfahrPMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45page7rOZNfahrPMeta || {},
    alias: thank_45you_45page7rOZNfahrPMeta?.alias || [],
    redirect: thank_45you_45page7rOZNfahrPMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingoYak3nS9i7Meta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingoYak3nS9i7Meta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingoYak3nS9i7Meta || {},
    alias: thankyou_45recruitingoYak3nS9i7Meta?.alias || [],
    redirect: thankyou_45recruitingoYak3nS9i7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralc1iQ5pY7NdMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralc1iQ5pY7NdMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralc1iQ5pY7NdMeta || {},
    alias: thankyou_45referralc1iQ5pY7NdMeta?.alias || [],
    redirect: thankyou_45referralc1iQ5pY7NdMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___en-US",
    path: thankyouALcvY9QmeIMeta?.path ?? "/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___ca",
    path: thankyouALcvY9QmeIMeta?.path ?? "/ca/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___nl",
    path: thankyouALcvY9QmeIMeta?.path ?? "/nl/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___uk",
    path: thankyouALcvY9QmeIMeta?.path ?? "/uk/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___de",
    path: thankyouALcvY9QmeIMeta?.path ?? "/de/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___mx",
    path: thankyouALcvY9QmeIMeta?.path ?? "/mx/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___fr",
    path: thankyouALcvY9QmeIMeta?.path ?? "/fr/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouALcvY9QmeIMeta?.name ?? "thankyou___fr-ca",
    path: thankyouALcvY9QmeIMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouALcvY9QmeIMeta || {},
    alias: thankyouALcvY9QmeIMeta?.alias || [],
    redirect: thankyouALcvY9QmeIMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteDpKWiBVGlOMeta?.name ?? "tiers-elite___uk",
    path: eliteDpKWiBVGlOMeta?.path ?? "/uk/tiers/elite",
    meta: eliteDpKWiBVGlOMeta || {},
    alias: eliteDpKWiBVGlOMeta?.alias || [],
    redirect: eliteDpKWiBVGlOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteDpKWiBVGlOMeta?.name ?? "tiers-elite___en-US",
    path: eliteDpKWiBVGlOMeta?.path ?? "/tiers/elite",
    meta: eliteDpKWiBVGlOMeta || {},
    alias: eliteDpKWiBVGlOMeta?.alias || [],
    redirect: eliteDpKWiBVGlOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteDpKWiBVGlOMeta?.name ?? "tiers-elite___ca",
    path: eliteDpKWiBVGlOMeta?.path ?? "/ca/tiers/elite",
    meta: eliteDpKWiBVGlOMeta || {},
    alias: eliteDpKWiBVGlOMeta?.alias || [],
    redirect: eliteDpKWiBVGlOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: pluskY21Zze1qBMeta?.name ?? "tiers-plus___uk",
    path: pluskY21Zze1qBMeta?.path ?? "/uk/tiers/plus",
    meta: pluskY21Zze1qBMeta || {},
    alias: pluskY21Zze1qBMeta?.alias || [],
    redirect: pluskY21Zze1qBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: pluskY21Zze1qBMeta?.name ?? "tiers-plus___en-US",
    path: pluskY21Zze1qBMeta?.path ?? "/tiers/plus",
    meta: pluskY21Zze1qBMeta || {},
    alias: pluskY21Zze1qBMeta?.alias || [],
    redirect: pluskY21Zze1qBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: pluskY21Zze1qBMeta?.name ?? "tiers-plus___ca",
    path: pluskY21Zze1qBMeta?.path ?? "/ca/tiers/plus",
    meta: pluskY21Zze1qBMeta || {},
    alias: pluskY21Zze1qBMeta?.alias || [],
    redirect: pluskY21Zze1qBMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierclitnEkAf7Meta?.name ?? "tiers-premier___uk",
    path: premierclitnEkAf7Meta?.path ?? "/uk/tiers/premier",
    meta: premierclitnEkAf7Meta || {},
    alias: premierclitnEkAf7Meta?.alias || [],
    redirect: premierclitnEkAf7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierclitnEkAf7Meta?.name ?? "tiers-premier___en-US",
    path: premierclitnEkAf7Meta?.path ?? "/tiers/premier",
    meta: premierclitnEkAf7Meta || {},
    alias: premierclitnEkAf7Meta?.alias || [],
    redirect: premierclitnEkAf7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierclitnEkAf7Meta?.name ?? "tiers-premier___ca",
    path: premierclitnEkAf7Meta?.path ?? "/ca/tiers/premier",
    meta: premierclitnEkAf7Meta || {},
    alias: premierclitnEkAf7Meta?.alias || [],
    redirect: premierclitnEkAf7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterCgaCFcpTpOMeta?.name ?? "tiers-starter___uk",
    path: starterCgaCFcpTpOMeta?.path ?? "/uk/tiers/starter",
    meta: starterCgaCFcpTpOMeta || {},
    alias: starterCgaCFcpTpOMeta?.alias || [],
    redirect: starterCgaCFcpTpOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterCgaCFcpTpOMeta?.name ?? "tiers-starter___en-US",
    path: starterCgaCFcpTpOMeta?.path ?? "/tiers/starter",
    meta: starterCgaCFcpTpOMeta || {},
    alias: starterCgaCFcpTpOMeta?.alias || [],
    redirect: starterCgaCFcpTpOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterCgaCFcpTpOMeta?.name ?? "tiers-starter___ca",
    path: starterCgaCFcpTpOMeta?.path ?? "/ca/tiers/starter",
    meta: starterCgaCFcpTpOMeta || {},
    alias: starterCgaCFcpTpOMeta?.alias || [],
    redirect: starterCgaCFcpTpOMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___en-US",
    path: processzAF76eC8q7Meta?.path ?? "/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___ca",
    path: processzAF76eC8q7Meta?.path ?? "/ca/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___nl",
    path: processzAF76eC8q7Meta?.path ?? "/nl/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___uk",
    path: processzAF76eC8q7Meta?.path ?? "/uk/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___de",
    path: processzAF76eC8q7Meta?.path ?? "/de/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___mx",
    path: processzAF76eC8q7Meta?.path ?? "/mx/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___fr",
    path: processzAF76eC8q7Meta?.path ?? "/fr/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processzAF76eC8q7Meta?.name ?? "trial-process___fr-ca",
    path: processzAF76eC8q7Meta?.path ?? "/fr-ca/trial/process",
    meta: processzAF76eC8q7Meta || {},
    alias: processzAF76eC8q7Meta?.alias || [],
    redirect: processzAF76eC8q7Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: support7zGgYXsJnyMeta?.name ?? "warranty-support___uk",
    path: support7zGgYXsJnyMeta?.path ?? "/uk/warranty/support",
    meta: support7zGgYXsJnyMeta || {},
    alias: support7zGgYXsJnyMeta?.alias || [],
    redirect: support7zGgYXsJnyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support7zGgYXsJnyMeta?.name ?? "warranty-support___en-US",
    path: support7zGgYXsJnyMeta?.path ?? "/warranty/support",
    meta: support7zGgYXsJnyMeta || {},
    alias: support7zGgYXsJnyMeta?.alias || [],
    redirect: support7zGgYXsJnyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support7zGgYXsJnyMeta?.name ?? "warranty-support___ca",
    path: support7zGgYXsJnyMeta?.path ?? "/ca/warranty/support",
    meta: support7zGgYXsJnyMeta || {},
    alias: support7zGgYXsJnyMeta?.alias || [],
    redirect: support7zGgYXsJnyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support7zGgYXsJnyMeta?.name ?? "warranty-support___ie",
    path: support7zGgYXsJnyMeta?.path ?? "/ie/warranty/support",
    meta: support7zGgYXsJnyMeta || {},
    alias: support7zGgYXsJnyMeta?.alias || [],
    redirect: support7zGgYXsJnyMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___en-US",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___ca",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___nl",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___uk",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___de",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___mx",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___fr",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UaPEkUBKd6Meta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93UaPEkUBKd6Meta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93UaPEkUBKd6Meta || {},
    alias: _91slug_93UaPEkUBKd6Meta?.alias || [],
    redirect: _91slug_93UaPEkUBKd6Meta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___en-US",
    path: indexGBmgmhVFEuMeta?.path ?? "/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___ca",
    path: indexGBmgmhVFEuMeta?.path ?? "/ca/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___nl",
    path: indexGBmgmhVFEuMeta?.path ?? "/nl/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___uk",
    path: indexGBmgmhVFEuMeta?.path ?? "/uk/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___de",
    path: indexGBmgmhVFEuMeta?.path ?? "/de/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___mx",
    path: indexGBmgmhVFEuMeta?.path ?? "/mx/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___fr",
    path: indexGBmgmhVFEuMeta?.path ?? "/fr/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexGBmgmhVFEuMeta?.name ?? "webinars___fr-ca",
    path: indexGBmgmhVFEuMeta?.path ?? "/fr-ca/webinars",
    meta: indexGBmgmhVFEuMeta || {},
    alias: indexGBmgmhVFEuMeta?.alias || [],
    redirect: indexGBmgmhVFEuMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j8bgaki8pGMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93j8bgaki8pGMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93j8bgaki8pGMeta || {},
    alias: _91slug_93j8bgaki8pGMeta?.alias || [],
    redirect: _91slug_93j8bgaki8pGMeta?.redirect,
    component: () => import("/codebuild/output/src380723514/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]